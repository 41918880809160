<template>
  <Gates url="/api/v1/apartment/save-tolotrack-gates/"></Gates>
</template>

<script>
import Gates from "../AccessControl/Tolotrack/Gates";

export default {
  components: { Gates },
};
</script>

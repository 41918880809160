<template>
  <v-container fluid>
    <v-row>
      <!-- RentalContracts -->
      <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          title="Vuokrasopimukset"
          subtitle="Voimassa olevat / potentiaaliset"
          icon="file-document-edit-outline"
          :amount="`${contractCount} / ${concurrentContracts} kpl`"
          color="info"
        ></basic-card>
      </v-col>
      <!-- Rent Amount Per Month -->
      <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          title="Laskutus kuluva kuu"
          subtitle="Kokonaislaskutus tässä kuussa"
          icon="currency-eur"
          :amount="`${formatCurrency(cashFlowThisMonth)}`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- Rent Amount This year -->
      <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          :title="`Laskutus ${new Date().getFullYear()}`"
          subtitle="Kokonaislaskutus kuluvana vuonna"
          icon="currency-eur"
          :amount="`${formatCurrency(cashFlowThisYear)}`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- Average rent time -->
      <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          title="Vuokran kesto"
          subtitle="Keskimääräinen sopimuksen pituus"
          icon="clock-outline"
          :amount="`${parseInt(avgTimeDiffWeeks / 4)} kk`"
          color="yellow"
        ></basic-card>
      </v-col>
      <!-- ROI
      <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          title="Vuokratuotto ROI"
          subtitle="Sijoitetun pääoman bruttotuotto voimassa olevista sopimuksista"
          icon="percent-outline"
          :amount="`${roi}`"
          color="info"
        ></basic-card>
      </v-col> -->
    </v-row>

    <!-- Vuokrattavuus -->
    <!-- <v-row>
      <v-col>
        <h1 class="mt-5">Tehokkuus</h1>
      </v-col>
    </v-row>
    <v-row> -->
    <!-- Käyttöaste -->
    <!-- <v-col cols="12" md="5" lg="4" xl="3">
        <basic-card
          title="Käyttöaste"
          subtitle="Käyttöaste viim. 365 päivää"
          icon="percent-outline"
          :amount="`${occupancyRate} %`"
          color="blue"
        ></basic-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import BasicCard from "../../components/Statistics/BasicCard";

export default {
  title: "Kohdetilastot",

  components: {
    BasicCard,
  },

  mixins: [mixins],

  data() {
    return {
      // roi: 0,
      contractCount: 0,
      concurrentContracts: 0,
      cashFlowThisYear: 0,
      cashFlowThisMonth: 0,
      avgTimeDiffWeeks: 0,
    };
  },

  async created() {
    try {
      let res = await axiosMethods.get(
        `/api/v1/statistics/getApartmentStats/${this.$route.params.id}`
      );
      let apartment = res.data.apartment;
      let invoiceData = res.data.invoices[0];
      this.avgTimeDiffWeeks = res.data.avgTimeDiffWeeks;

      this.contractCount = apartment.contract.length;
      this.concurrentContracts = apartment.concurrentContracts;
      this.cashFlowThisYear =
        invoiceData.cashFlowThisYear.length > 0 ? invoiceData.cashFlowThisYear[0].totalAmount : 0;
      this.cashFlowThisMonth =
        invoiceData.cashFlowThisMonth.length > 0 ? invoiceData.cashFlowThisMonth[0].totalAmount : 0;
      // this.roi = this.calcRoi(apartment);
    } catch (err) {
      this.showPopup("Joidenkin tilastojen haku epäonnistui", "error");
    }
  },

  methods: {
    // calcRoi(apartment) {
    //   if (apartment.contract.length < 1 || !apartment.priceWithTaxes) {
    //     return "Ei laskettavissa";
    //   }
    //   const maintenanceCharge = !apartment.maintenanceCharge ? 0 : apartment.maintenanceCharge;
    //   const amountsPerYear = apartment.contract.map((el) => {
    //     console.log(el.lease);
    //     return (el.lease.rent - maintenanceCharge) * 12;
    //   });
    //   const totalRent = amountsPerYear.reduce((a, b) => a + b, 0);
    //   let roi = (totalRent / apartment.priceWithTaxes) * 100;
    //   console.log(roi);
    //   return roi ? roi.toFixed(2) + " " + "%" : "Ei laskettavissa";
    // },
  },
};
</script>

<style scoped></style>
